// Resume.js
import React, { useEffect } from 'react';

const Resume = () => {
  useEffect(() => {
    window.location.href = '/Michelle_Flandin_Resume.pdf';
  }, []);

  return null; 
};

export default Resume;
